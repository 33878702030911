;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"4f529cac6fe4c3cd2aac8d67eb7c1404bd327f2b"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/plp";import * as Sentry from '@sentry/nextjs';
import type { BrowserOptions } from '@sentry/nextjs';
import type { Client } from '@sentry/types';

import sharedSentryOptions from './shared-sentry-options';

export const initSentry = (
    additionalOptions: BrowserOptions
): Client | undefined =>
    Sentry.init({
        ...sharedSentryOptions,
        ...additionalOptions,
    });
